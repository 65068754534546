import Head from 'next/head';
import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import { fetchCms } from 'lib/api';
// import { RichTextRenderer } from 'lib/rich-text-renderer';

export default function Home({ heroSection }) {
	const imageRefs = useRef([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleDowndown = (e) => {
		e.currentTarget.getAttribute('aria-expanded') === 'true' ? e.currentTarget.setAttribute('aria-expanded', false) : e.currentTarget.setAttribute('aria-expanded', true);
	}
	 
	useEffect(() => {
		setTimeout(() => {
			if (imageRefs.current.length === 0) return;
			imageRefs.current[currentIndex]?.removeAttribute('aria-label')
			const nextIndex = (currentIndex + 1) % 3;
			setCurrentIndex(nextIndex);
			imageRefs.current[nextIndex]?.setAttribute('aria-label', 'true')
		}, 7000)
	}, [currentIndex]);
	
  return (
    <>
      <Head>
        <title>
				Web Design and Development Services in Canada | Wifigen.ca 
			</title>
		  	<meta name='description' content='Empower Your Business Online: Crafting Unique and Effective Online Solutions for Small to Medium Businesses Across Canada' />
      </Head>
		<header className='hero-left-text-right-img'>
			<div>
				<div>
					<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8lnskzff0-open-1.jpg' alt='slider' fill priority={ true } aria-label='true' ref={ ele => imageRefs.current[0] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
					<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8lnskzfgj-open-2.jpg' alt='slider' fill priority={ true } ref={ ele => imageRefs.current[1] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
					<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8lnskzffm-open-3.jpg' alt='slider' fill priority={ true } ref={ ele => imageRefs.current[2] = ele }  sizes='(max-width: 768px) 100vw, 33vw' />
				</div>
				<div>
					{/* <RichTextRenderer data={ heroSection.heroText } /> */}
					<h1>Boost Your Local Business Online</h1>
					<p>Elevating small businesses with innovative web solutions: Enhance your online presence and unlock greater success.</p>
					<p><Link href='/contact' className='btn-cta'>Start Today</Link></p>
				</div>
			</div>
		</header>
		<div className='main-content'>
			<section className='three-card'>
				<h1>Discover the benefits</h1>
				<article>
					<div>
						<p>Enhanced Visibility</p>
						<p>Our websites are designed to boost your online presence, helping more potential customers discover your business.</p>
					</div>
					<div>
						<p>Improved User Experience</p>
						<p>We create user-friendly websites that make it easy for visitors to find information and engage with your brand.</p>
					</div>
					<div>
						<p>Mobile-Ready Solutions</p>
						<p>Your site will look and perform great on mobile devices, reaching customers wherever they are.</p>
					</div>
				</article>
			</section>
			<section className='content-right full-bg-white'>
				<h1>Why Choose Us</h1>
				<article>
					<div >
						<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8lntvg8uf-service-1.jpg' alt='' className='img-desktop' fill loading='lazy' sizes='(max-width: 768px) 100vw, 33vw' />
						<Image src='https://d1kdcmsg84yapz.cloudfront.net/files/8lnu3h2cz-service-1-mobile.jpg' alt='' className='img-mobile' fill loading='lazy' sizes='(max-width: 768px) 100vw, 33vw' />
					</div>
					<div>
						<h1>
							Flexible Web Development Services:
						</h1>
						<ul>
							<li>
								<strong>Customized Solutions:</strong> Tailored to your specific needs and goals, with pricing based on the scope of work, additional pages, and project requirements.
							</li>
							<li>
								<strong>Full Ownership:</strong> You maintain control over your domain, content, listings, and profiles, with the freedom to cancel anytime without fees or hassle.
							</li>
							<li>
								<strong>Inclusive Hosting:</strong> Hosting fees are conveniently included in our comprehensive service. Unlimited Revisions: Make changes as you see fit, and we'll promptly implement them.
							</li>
							<li>
								<strong>24/7 Support:</strong> Reach us directly, any time of day or night, without navigating phone trees or automated responses.
							</li>
							<li>
								<strong>Extensive Design & Development:</strong> Benefit from over 40 hours of design, development, and testing expertise.
							</li>
							<li>
								<strong>Citations and Listings:</strong> Ensure your website is well-connected across the web, including directories.
							</li>
							<li>
								<strong>Google Analytics:</strong> We provide free installation of Analytics for detailed traffic monitoring and insights into the sources of your web traffic.
							</li>
						</ul>
					</div>
				</article>
			</section>
			<section className='full-content-list'>
				<h1>Frequently Asked Questions</h1>
				<article>
					<ul>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>What types of websites do you specialize in?</p>
							<p>We specialize in creating websites for a variety of businesses, from small startups to established companies.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>How long does it take to build a website?</p>
							<p>The timeline varies based on the project's scope and complexity, but we work efficiently to ensure timely delivery.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Do you provide ongoing maintenance and support?</p>
							<p>Yes, we offer maintenance and support services to keep your website running smoothly after the launch.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Can you help with website content and copywriting?</p>
							<p>Yes, we offer content development services to ensure your website's content is engaging and effective.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Is SEO included in your web development services?</p>
							<p>Yes, we optimize websites for search engines to improve visibility and search ranking.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>What is the cost of your web development services?</p>
							<p>Our pricing varies depending on your specific project requirements. Contact us for a customized quote.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Do you offer e-commerce website development?</p>
							<p>Yes, we provide e-commerce solutions tailored to your business needs.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Can you redesign an existing website?</p>
							<p>Absolutely, we offer website redesign services to enhance the look and performance of existing sites.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>How do I update content on my website after it's live?</p>
							<p>We provide easy-to-use content management systems (CMS) for you to make updates without technical knowledge.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Do you create mobile-friendly websites?</p>
							<p>Yes, all our websites are designed to be fully responsive and mobile-friendly.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>What are the payment terms and methods you accept?</p>
							<p>Our payment terms are flexible, and we accept a variety of payment methods for your convenience.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Will I have ownership of my website and code?</p>
							<p>You will have complete ownership of your website and its source code.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Do you provide hosting and domain registration services?</p>
							<p>We can assist you with hosting and domain registration, ensuring a seamless setup.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Can you integrate third-party tools or services into my website?</p>
							<p>Yes, we can integrate various third-party tools, such as payment gateways, analytics, and more.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>What is your process for handling revisions or changes to the website?</p>
							<p>We have a structured process for handling revisions and changes efficiently.</p>
						</li>
						<li onClick={ (e) => handleDowndown(e) } >
							<p>Do you offer security measures to protect my website from cyber threats?</p>
							<p>Yes, we prioritize website security and implement measures to protect against threats.</p>
						</li>
					</ul>
				</article>
			</section>
			<section className='sbs-banner'>
				<article>
					<div>
						<h1>Ready to Elevate Your Online Presence?</h1>
						<Link href='/contact' className='btn-cta'>Get in Touch</Link>
					</div>
					<div>
						<p>Let's discuss how our web development services can help your business thrive</p>
					</div>
				</article>
			</section>
      </div>
    </>
  )
}

// export async function getStaticProps() {
// 	const response = await fetchCms(`{
// 		listWifigenHomepages {
// 			data {
// 				id
// 				heroSection {
// 					heroImage
// 					heroBackgroundImage
// 					heroText
// 				}
// 			}
// 		}
//   	}`)
// 	return {
// 		props: {
// 			heroSection: response.data.listWifigenHomepages.data[0].heroSection,
// 		}
// 	}
// }